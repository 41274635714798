type User = {
    name: string;
    email?: string;
  };
  
  // Value is initialized in: ~/plugins/auth.ts
  export const useUser = () => {
    return useState<User>("user", () => undefined);
  };
  
  export const useAuth = () => {
    const router = useRouter();
    const { t, locale } = useI18n({ useScope: 'global' })
    const user = useUser();
    const isLoggedIn = computed(() => !!user.value);
  
    async function refresh() {
      try {
        user.value = await fetchCurrentUser();
      } catch {
        user.value = null;
      }
    }

    async function login(credentials) {
      if (isLoggedIn.value) return;
  
      await $larafetch("/login", { method: "post", body: credentials }).then(response => {
        if(typeof response.lang != 'undefined' && ['it', 'en'].includes(response.lang)) {
          locale.value = response.lang;
        }
      });
      await refresh();
    }

    async function leaveImpersonation() {
      await $larafetch("/ccare/leaveImpersonation");
      await refresh();
      document.location.href = "/";
    }
    
    async function impersonate(id) {
      await $larafetch("/ccare/impersonate/" + id);
      await refresh();
      document.location.href = "/";
    }
  
    async function register(credentials) {
      if (isLoggedIn.value) return;

      await $larafetch("/register", { method: "post", body: credentials });
      await refresh();
    }
  
    async function resendEmailVerification() {
      return await $larafetch<{ status: string }>("/email/verification-notification", {
        method: "post",
      });
    }
  
    async function logout() {
      if (!isLoggedIn.value) return;
  
      await $larafetch("/logout", { method: "post" });
      user.value = null;
  
      await router.push("/auth/login");
    }
  
    async function forgotPassword(email) {
      return await $larafetch<{ status: string }>("/password/email", {
        method: "post",
        body: { email },
      });
    }
  
    async function resetPassword(credentials) {
      return await $larafetch<{ status: string }>("/password/reset", {
        method: "post",
        body: credentials,
      });
    }
  
    return {
      user,
      isLoggedIn,
      login,
      leaveImpersonation,
      impersonate,
      register,
      resendEmailVerification,
      logout,
      forgotPassword,
      resetPassword,
      refresh,
    };
  };
  
  export const fetchCurrentUser = async () => {
    try {
      return await $larafetch<User>("/api/user", {}, false);
    } catch (error) {
      if ([401, 419].includes(error?.response?.status)) {
        return null;
      }

      throw error;
    }
  };