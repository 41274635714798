{
    "All": "All",
    "Login": "Login",
    "Login in to your account": "Login in to your account",
    "Or": "Or",
    "create your free account": "create your free account",
    "Email address": "Email address",
    "Remember me": "Remember me",
    "Password": "Password",
    "Forgot your password?": "Forgot your password?",
    "Recovery": "Recovery",
    "Password recovery": "Password recovery",
    "Register": "Register",
    "Create your free account": "Create your free account",
    "login in to your account": "login in to your account",
    "Plans": "Plans",
    "Open sidebar": "Open sidebar",
    "View notifications": "View notifications",
    "Open user menu": "Open user menu",
    "Settings": "Settings",
    "Sign out": "Sign out",
    "Close sidebar": "Close sidebar",
    "Dashboard": "Dashboard",
    "Requests": "Requests",
    "Notifications": "Notifications",
    "Analytics": "Analytics",
    "Organization": "Organization",
    "Movements": "Movements",
    "Search": "Search",
    "Default incoming": "Default incoming",
    "Allow self ship": "Allow self ship",
    "Allow store return": "Allow store return",
    "New address": "New address",
    "Add": "Add",
    "Edit": "Edit",
    "Change": "Change",
    "Save": "Save",
    "Cancel": "Cancel",
    "Remove": "Remove",
    "Setting": "Setting",
    "free": "free",
    "Waiting...": "Waiting...",
    "Choose your plan": "Choose your plan",
    "Continue": "Continue",
    "Step 1": "Step 1",
    "Step 2": "Step 2",
    "Step 3": "Step 3",
    "Request number": "Request number",
    "Order": "Order",
    "Customer": "Customer",
    "Shipment": "Shipment",
    "History": "History",
    "Locations": "Locations",
    "Automation rules": "Automation rules",
    "Automations": "Automations",
    "Restock": "Restock",
    "Refunds methods": "Refunds methods",
    "Return reasons": "Return reasons",
    "Order lookup": "Order lookup",
    "Returns Page": "Returns Page",
    "Shipping rules": "Shipping rules",
    "Store locations": "Store locations",
    "Customizations": "Customizations",
    "Rules":"Rules",
    "Home": "Home",
    "Pending decision": "Pending decision",
    "Declined": "Declined",
    "Paused": "Paused",
    "Checking goods": "Checking goods",
    "Waiting goods": "Waiting goods",
    "Completed": "Completed",
    "Archivied": "Archivied",
    "Code": "Code",
    "Refund methods": "Refund methods",
    "No results": "No result currently available.",
    "Loading": "Loading",
    "Your requests": "Your requests",
    "Name": "Name",
    "Email": "Email",
    "Address": "Address",
    "City": "City",
    "Zip code": "Zip code",
    "Phone": "Phone",
    "Notifications Language": "Notifications language",
    "Created at": "Create at",
    "Country": "Country",
    "Province / State": "Province / State",
    "TAX ID": "Tax code",
    "Click the below link to start the Magento configuration wizard, then come back here!": "Click the below link to start the Magento configuration wizard, then come back here!",
    "Setup integration": "Setup integration",
    "Please visit the Shopify App Store and complete the integration, then come back here.": "Please visit the Shopify App Store and complete the integration, then come back here.",
    "Go to Shopify App Store": "Go to Shopify App Store",
    "Alright! You have choose your plan! If you change your mind you can change plan in the next": "Alright! You have choose your plan! If you change your mind you can change plan in the next ",
    "You haven't choose any plan! Hurry up you have": "You haven't choose any plan! Hurry up you have ",
    " to make your decision!": " to make your decision!",
    "day": " 1 day | {count} days ",
    "Go to plans": "See all plans",
    "Addresses": "Store Addresses",
    "Here you can set your store addresses": "Here you can set your store addresses",
    "Notification sender": "Notification sender",
    "Sending domain": "Sending domain",
    "Sending email": "Sending email",
    "Sending name": "Sending name",
    "Sending token": "Sending token",
    "Validate and save": "Validate and save",
    "Would you like to personalize the sender of your communications?": "Would you like to personalize the sender of your communications?",
    "Contact support to complete the procedure. All you need to do is have access to the DNS in your domain and follow simple instructions. Once the settings have been verified, you can proceed with the customization.": "Contact support to complete the procedure. All you need to do is have access to the DNS in your domain and follow simple instructions. Once the settings have been verified, you can proceed with the customization.",
    "status": "Status",
    "ssl": "SSL",
    "status.pending": "Pending",
    "status.creating": "Creating",
    "status.enabled": "Enabled",
    "status.pending_delete": "Pending delete",
    "ssl.not_available": "Not available",
    "ssl.provisioning": "Provisioning",
    "ssl.provisioned": "Provisioned",
    "ssl.failed": "Failed",
    "Delete": "Delete",
    "You're domains": "You're domains",
    "Setup custom domain": "Setup custom domain",
    "custom domain": "custom domain",
    "integrations": "E-commerce integrations",
    "Order retrive": "Order retrive",
    "Ask for order reference": "Ask for order reference",
    "Ask for customer email": "Ask for customer email",
    "Refund method": "Choose you're action",
    "Here you can decide how the user will be refunded": "Here you can decide how the user will be refunded",
    "Here you can decide how the user will return your product.": "Here you can decide how the user will return your product and how much it will be cost.",
    "Missing informations": "'Missing informations",
    "Reasons for the return": "Reasons for the return",
    "In this section you can manage the return reasons for individual items": "In this section you can manage the return reasons for individual items",
    "New motivation": "New motivation",
    "Free shipping": "Free shipping",
    "Voucher settings": "Voucher settings",
    "Restock rule": "Restock rule",
    "Create automatically": "Create automatically",
    "Here you can define the time window for you refunds": "Here you can define the time window for you refunds",
    "Time window for return": "Time window for return",
    "days": "days",
    "Max time": "Max time",
    "Generation rule": "Generation rule",
    "Your data": "Your data",
    "Start": "Start",
    "Configure the platform with six easy steps!": "Configure the platform with six easy steps!",   
    "Your movements": "Your statements",
    "Your notifications": "Your notifications",
    "Your organization": "Your organization",
    "Your settings": "Your settings",
    "Connect your Magento to SendBack": "Connect your Magento to SendBack",
    "connect": "connect",
    "Close window": "Close window",
    "Sendback - Connect Magento Store": "Sendback - Connect Magento Store",
    "Something went wrong.": "Something went wrong.",
    "Shop successfull connected!": "Shop successfull connected!",
    "Now you can close this window and proceed with the onboarding": "Now you can close this window and proceed with the onboarding",
    "Complete your Shopify Store link to SendBack": "Complete your Shopify Store link to SendBack",
    "logged_sameEmail": "current user",
    "logged_differentEmail": "logged in",
    "guest_registeredEmail": "account matched",
    "guest_newClient": "new user",
    "Please enter it below to verify yout identity.": "Please enter it below to verify yout identity.",
    "We have sent a 8 characted code to your mail": "We have sent a 8 characted code to your mail",
    "Sendback - Connect Shopify": "Sendback - Connect Shopify",
    "Please return to shopify app store and retry.": "Please return to shopify app store and retry.",
    "Register for the service": "Register for the service",
    "Integrate your ecommerce": "Integrate your ecommerce",
    "Return prefereces": "Return prefereces",
    "These credentials do not match our records.": "These credentials do not match our records.",
    "The field is required": "This field is required.",
    "The password field is required.": "The password field is required.",
    "The name field is required.": "The name field is required.",
    "The email field is required.": "The email field is required.",
    "The line1 field is required.": "The address field is required.",
    "The postal code field is required.": "The zip code field is required.",
    "The country field is required.": "The country field is required.",
    "The city field is required.": "The city field is required.",
    "The state field is required.": "The state field is required.",
    "The tax id field is required when country is IT.": "The tex code field is required.",
    "The email field must be a valid email address.": "The email field must be a valid email address.",
    "The password field must be at least 8 characters.": "The password field must be at least 8 characters.",
    "status.created": "Return created",
    "shipment.created": "Shipment created",
    "shipment.waiting_movement": "Shipment ready",
    "Add a note": "Add a note",
    "Update note": "Update note",
    "Discard": "Discard",
    "Update": "Update",
    "Notes": "Notes",
    "Variant": "Variant",
    "Search or create tag": "Search or create tag",
    "Italian": "Italian",
    "English": "English",
    "German": "German",
    "Here you can define the settting for the restock option": "Here you can define the settting for the restock option",
    "Add carrier": "Add carrier",
    "Update carrier": "Update carrier",
    "Add addresses": "Add addresses",
    "Add packages": "Add packages",
    "Update packages": "Update packages",
    "Generate return labels in just 3 steps": "Generate return labels in just 3 steps",
    "Manual insert": "Manual insert",
    "You will insert manually the shipping information and provide to your customer the shipping label.": "You will insert manually the shipping information and provide to your customer the shipping label.",
    "Let SendBack carry all the shipping stuff": "Let SendBack carry all the shipping stuff",
    "Use my SpedirePro account for shipping.":"Use my SpedirePro account for shipping.",
    "Courier": "Courier",
    "New packages": "New packages",
    "Here you can set your default packages": "Here you can set your default packages",
    "Here you can set your shipping preferences": "Here you can set your shipping preferences",
    "Configure courier": "Configure courier",
    "Packages": "Packages"
}