import revive_payload_client_4sVQNw7RlN from "/home/sendadmstaging/admin.staging.sendback.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/sendadmstaging/admin.staging.sendback.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/sendadmstaging/admin.staging.sendback.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/sendadmstaging/admin.staging.sendback.com/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/sendadmstaging/admin.staging.sendback.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/sendadmstaging/admin.staging.sendback.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/home/sendadmstaging/admin.staging.sendback.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/sendadmstaging/admin.staging.sendback.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_OcT8LIvbNC from "/home/sendadmstaging/admin.staging.sendback.com/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/sendadmstaging/admin.staging.sendback.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_ANExhJHvcR from "/home/sendadmstaging/admin.staging.sendback.com/plugins/auth.js";
import bus_RrsLoRnN1Z from "/home/sendadmstaging/admin.staging.sendback.com/plugins/bus.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_OcT8LIvbNC,
  chunk_reload_client_UciE0i6zes,
  auth_ANExhJHvcR,
  bus_RrsLoRnN1Z
]