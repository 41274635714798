{
    "All": "Tutti",
    "Login": "Accedi",
    "Login in to your account": "Accedi al tuo account",
    "Or": "oppure",
    "create your free account": "crea gratuitamente il tuo account",
    "Email address": "Indirizzo email",
    "Remember me": "Ricordarmi",
    "Password": "Password",
    "Forgot your password?": "Hai dimenticato la password?",
    "Recovery": "Recupera",
    "Password recovery": "Recupera password",
    "Register": "Registrati",
    "Create your free account": "Crea gratuitamente il tuo account",
    "login in to your account": "accedi al tuo account",
    "Plans": "Piani",
    "Open sidebar": "Apri menu",
    "View notifications": "Mostra notifiche",
    "Open user menu": "Api menu utente",
    "Settings": "Impostazioni",
    "Sign out": "Disconnetti",
    "Close sidebar": "Chiudi menu",
    "Dashboard": "Dashboard",
    "Requests": "Richieste",
    "Notifications": "Notifiche",
    "Analytics": "Statistiche",
    "Organization": "Organizzazione",
    "Movements": "Movimenti",
    "Search": "Cerca",
    "Default incoming": "Indirizzo reso predefinito",
    "Allow self ship": "Consenti spedizioni",
    "Allow store return": "Consenti reso fisico",
    "New address": "Nuovo indirizzo",
    "Add": "Aggiungi",
    "Edit": "Modifica",
    "Change": "Cambia",
    "Save": "Salva",
    "Cancel": "Annulla",
    "Remove": "Rimuovi",
    "Setting": "Impostazioni",
    "delete": "Rimuovi",
    "free": "gratuito",
    "Waiting...": "Attendere...",
    "Choose your plan": "Scegli il tuo piano",
    "Continue": "Continua",
    "Step 1": "Step 1",
    "Step 2": "Step 2",
    "Step 3": "Step 3",
    "Request number": "Richiesta numero",
    "Order": "Ordine",
    "Customer": "Cliente",
    "Shipment": "Spedizione",
    "History": "Storico",
    "Locations": "Sedi",
    "Automation rules": "Automazioni",
    "Automations": "Automations",
    "Restock": "Restock",
    "Refunds methods": "Metodi di rimborso",
    "Return reasons": "Motivazioni reso",
    "Order lookup": "Recupero ordini",
    "Returns Page": "Pagina di reso",
    "Shipping rules": "Regole spedizioni",
    "Store locations": "Sedi",
    "Customizations": "Personalizzazioni",
    "Rules":"Regole",
    "Home": "Home",
    "Pending decision": "Da approvare",
    "Declined": "Rifiutata",
    "Paused": "In attesa",
    "Checking goods": "Verifica della merce",
    "Waiting goods": "In attesa della merce",
    "Completed": "Completata",
    "Archivied": "Archiviata",
    "Code": "Codice",
    "Refund methods": "Modalità di reso",
    "No results": "Nessun risultato al momento disponibile.",
    "Loading": "Caricamento",
    "Your requests": "Le tue richieste",
    "Name": "Nome",
    "Email": "Email",
    "Address": "Indirizzo",
    "City": "Città",
    "Zip code": "Cap",
    "Phone": "Telefono",
    "Notifications Language": "Lingua notifiche",
    "Created at": "Creato il",
    "Country": "Nazione",
    "Province / State": "Provincia",
    "TAX ID": "Partiva Iva",
    "Click the below link to start the Magento configuration wizard, then come back here!": "Clicca il bottone quì sotto per iniziare il wizard di configurazione del tuo store Magento v2, quando hai finito torna quì.",
    "Click the below link to start the custom configuration wizard, then come back here!": "Clicca il bottone quì sotto per iniziare il wizard di configurazione del tuo store custom, quando hai finito torna quì.",
    "Setup integration": "Configura integrazione",
    "Please visit the Shopify App Store and complete the integration, then come back here.": "Per completare l'integrazione visita Shopify App Store e segui le istruzioni.",
    "Go to Shopify App Store": "Vai allo Shopify App Store",
    "Alright! You have choose your plan! If you change your mind you can change plan in the next": "Ottimo! Hai scelto il tuo piano! Se cambi idea potrai modificare il piano entro ",
    "You haven't choose any plan! Hurry up you have": "Non hai ancora scelto un piano! Sbrigati, ti rimangono ",
    " to make your decision!": " per la scelta!",
    "day": " 1 giorno| {count} giorni",
    "Go to plans": "Vedi i piani",
    "Addresses": "Indirizzi store",
    "Here you can set your store addresses": "Qui puoi definire la posizione dei tuoi store.",
    "Notification sender": "Mittente notifiche",
    "Sending domain": "Dominio d'invio",
    "Sending email": "Email d'invio",
    "Sending name": "Nome d'invio",
    "Sending token": "Token d'invio",
    "Validate and save": "Convalida e salva",
    "Would you like to personalize the sender of your communications?": "Desideri personalizzare il mittente delle tue comunicazioni?",
    "Contact support to complete the procedure. All you need to do is have access to the DNS in your domain and follow simple instructions. Once the settings have been verified, you can proceed with the customization.": "Contatta l'assistenza per completare la procedura. Ti basterà avere accesso ai DNS nel tuo dominio e seguire delle semplici istruzioni. Una volta verificate le impostazioni potrai procedere con la personalizzazione.",
    "status": "Stato",
    "ssl": "SSL",
    "status.pending": "In attesa",
    "status.creating": "Creazione",
    "status.enabled": "Attivo",
    "status.pending_delete": "In cancellazione",
    "ssl.not_available": "Non disponibile",
    "ssl.provisioning": "Creazione",
    "ssl.provisioned": "Creato",
    "ssl.failed": "Errore",
    "Delete": "Cancella",
    "You're domains": "I tuoi domini",
    "Setup custom domain": "Configura dominio personalizzato",
    "custom domain": "Dominio",
    "integrations": "Integrazione con e-commerce",
    "Order retrive": "Recupero ordini",
    "Ask for order reference": "Ask for order reference",
    "sk for customer email": "sk for customer email",
    "Refund method": "Scelta dell’azione",
    "Here you can decide how the user will be refunded": "Qui puoi decidere che azioni permettere all’utente che necessita del reso",
    "Here you can decide how the user will return your product.": "Qui puoi decidere le modalità di ritorno del tuo prodotto da parte dell’utente e il relativo costo.",
    "Missing informations": "'Informazioni mancanti",
    "Reasons for the return": "Motivazioni per il reso",
    "In this section you can manage the return reasons for individual items": "In questa sezione puoi andare a gestire le motivazione di reso sei singoli oggetti",
    "Free shipping": "Spedizione gratuita",
    "New motivation": "Nuova motivazione",
    "Voucher settings": "Impostazioni voucher",
    "Create automatically": "Crea automaticamente",
    "Restock rule": "Regola restock",
    "Here you can define the time window for you refunds": "Qui decidi il limite massimo di tempo entro cui si può aprire la procedura di reso di un prodotto",
    "Time window for return": "Finestra temporale per il reso",
    "days": "giorni",
    "Max time": "Finestra temporale",
    "Generation rule": "Regola generazione",
    "Your data": "I tuoi dati",
    "Start": "Comincia",
    "Configure the platform with six easy steps!": "Configura la piattaforma in sei semplici passi",  
    "Your movements": "I tuoi movimenti",
    "Your notifications": "Le tue notifiche",
    "Your organization": "La tua organizzazione",
    "Your settings": "Le tue impostazioni",
    "Connect your Magento to SendBack": "Connetti il tuo Magento a SendBack",
    "connect": "connetti",
    "Close window": "Chiudi finestra",
    "Sendback - Connect Magento Store": "Sendback - Connetti Magento Store",
    "Something went wrong.": "Ops!",
    "Shop successfull connected!": "Store connesso!",
    "Now you can close this window and proceed with the onboarding": "Ora puoi chiudere questa scheda e proseguire il processo di onboarding",
    "Complete your Shopify Store link to SendBack": "Complete your Shopify Store link to SendBack",
    "logged_sameEmail": "current user",
    "logged_differentEmail": "logged in",
    "guest_registeredEmail": "account matched",
    "guest_newClient": "new user",
    "Please enter it below to verify yout identity.": "Please enter it below to verify yout identity.",
    "We have sent a 8 characted code to your mail": "We have sent a 8 characted code to your mail",
    "Sendback - Connect Shopify": "Sendback - Connect Shopify",
    "Please return to shopify app store and retry.": "Please return to shopify app store and retry.",
    "Register for the service": "Registrati al servizio",
    "Integrate your ecommerce": "Integrazione del tuo ecommerce",
    "Return prefereces": "Impostazioni generali di reso",
    "These credentials do not match our records.": "Le credenziali inserite non sono corrette",
    "The field is required": "Il campo è obbligatorio.",
    "The password field is required.": "Il campo password è obbligatorio.",
    "The name field is required.": "Il campo nome è obbligatorio.",
    "The email field is required.": "Il campo email è obbligatorio.",
    "The line1 field is required.": "Il campo indirizzo è obbligatorio.",
    "The postal code field is required.": "Il campo cap è obbligatorio.",
    "The country field is required.": "Il campo nazione è obbligatorio.",
    "The city field is required.": "Il campo città è obbligatorio.",
    "The state field is required.": "Il campo provincia è obbligatorio.",
    "The tax id field is required when country is IT.": "Il campo partita iva è obbligatorio.",
    "The sdi field is required when country is IT.": "Il campo sdi è obbligatorio.",
    "The sdi field must be 7 characters.": "Il campo sdi deve contenere almeno 7 caratteri.",
    "The pec field is required when country is IT.": "Il campo pec è obbligatorio.",
    "The email field must be a valid email address.": "Il campo email deve essere un indirizzo email valido.",
    "The pec field must be a valid email address.": "Il campo pec deve essere un indirizzo email valido.",
    "The password field must be at least 8 characters.": "Il campo della password deve contenere almeno 8 caratteri.",
    "Preview": "Anteprima",
    "Show": "Mostra",
    "status.created": "Reso creato",
    "shipment.created": "Spedizione create",
    "shipment.waiting_movement": "Spedizione pronta",
    "Add a note": "Aggiungi nota",
    "Update note": "Aggiorna nota",
    "Discard": "Annulla",
    "Update": "Aggiorna",
    "Notes": "Note",
    "Variant": "Variante",
    "Search or create tag": "Cerca o crea tag",
    "Italian": "Italiano",
    "English": "Inglese",
    "German": "Tedesco",
    "Here you can define the settting for the restock option": "Qui puoi definire le impostazioni per il restock della merce",
    "Add carrier": "Aggiungi corriere",
    "Update carrier": "Aggiorna corriere",
    "Add addresses": "Aggiungi indirizzo",
    "Add packages": "Aggiungi pacchetto",
    "Update packages": "Aggiorna pacchetto",
    "Generate return labels in just 3 steps": "Genera etichette di reso in soli 3 step",
    "Manual insert": "Inserimento manuale",
    "You will insert manually the shipping information and provide to your customer the shipping label.": "Comunicherai manualmente le informazioni per il reso al tuo cliente",
    "Let SendBack carry all the shipping stuff": "Lascia gestire a SendBack le impostazioni",
    "Use my SpedirePro account for shipping.": "Usa il mio account SpedirePro per spedire.",
    "Courier": "Corriere",
    "New packages": "Nuovo pacchetto",
    "Here you can set your default packages": "Qui puoi impostare il tuo pacchetto predefinito",
    "Here you can set your shipping preferences": "Qui puoi impostare le tue preferenze di spedizione",
    "Configure courier": "Configura corriere",
    "Packages": "Pacchetti"
}