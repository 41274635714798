import { default as analyticsjHwCAhHYgFMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/analytics.vue?macro=true";
import { default as logingK2JN5LSVSMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/auth/login.vue?macro=true";
import { default as recoveryycs7bKFZ9HMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/auth/recovery.vue?macro=true";
import { default as registerk5i5zWnRLhMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/auth/register.vue?macro=true";
import { default as verify_45emaildxQ5NDQSZUMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/auth/verify-email.vue?macro=true";
import { default as plansWLBuQYUGPsMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/billing/plans.vue?macro=true";
import { default as _91id_93duBgUJaaDoMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/customer-care/plans/[id].vue?macro=true";
import { default as index3lgHD8McG7Meta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/customer-care/plans/index.vue?macro=true";
import { default as usersLIQFoP25KhMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/customer-care/users.vue?macro=true";
import { default as homeUB2KnuWhItMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/home.vue?macro=true";
import { default as setupc34uEvDTQ1Meta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/integrations/custom/setup.vue?macro=true";
import { default as setupkTn8jcnpTOMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/integrations/magento/setup.vue?macro=true";
import { default as setupWYLqEa8JlYMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/integrations/shopify/setup.vue?macro=true";
import { default as splashrmEMQY18PhMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/integrations/shopify/splash.vue?macro=true";
import { default as movementsL4JBQDpjniMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/movements.vue?macro=true";
import { default as notificationsyFXQpskHIBMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/notifications.vue?macro=true";
import { default as settingLJSGG2TT6FMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/onboarding/setting.vue?macro=true";
import { default as organizationS9bR0ETI49Meta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/organization.vue?macro=true";
import { default as automations_45rules74dbNZt7dLMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/policy/automations-rules.vue?macro=true";
import { default as order_45lookupVk1sAVrAKJMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/policy/order-lookup.vue?macro=true";
import { default as refund_45methodsTviFekpzFAMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/policy/refund-methods.vue?macro=true";
import { default as return_45reasons27MFPudopUMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/policy/return-reasons.vue?macro=true";
import { default as _91id_93HgBTdpjzEvMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/request/[id].vue?macro=true";
import { default as requeststP7SvabLN7Meta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/requests.vue?macro=true";
import { default as returns_45pageE5tHE7AmSQMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/returns-page.vue?macro=true";
import { default as settings78H5Q5OjM0Meta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/settings.vue?macro=true";
import { default as shipping_45rulesrfjlkLMXUEMeta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/shipping-rules.vue?macro=true";
import { default as store_45locationss9kKSzpal6Meta } from "/home/sendadmstaging/admin.staging.sendback.com/pages/store-locations.vue?macro=true";
export default [
  {
    name: analyticsjHwCAhHYgFMeta?.name ?? "analytics",
    path: analyticsjHwCAhHYgFMeta?.path ?? "/analytics",
    meta: analyticsjHwCAhHYgFMeta || {},
    alias: analyticsjHwCAhHYgFMeta?.alias || [],
    redirect: analyticsjHwCAhHYgFMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/analytics.vue").then(m => m.default || m)
  },
  {
    name: logingK2JN5LSVSMeta?.name ?? "auth-login",
    path: logingK2JN5LSVSMeta?.path ?? "/auth/login",
    meta: logingK2JN5LSVSMeta || {},
    alias: logingK2JN5LSVSMeta?.alias || [],
    redirect: logingK2JN5LSVSMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: recoveryycs7bKFZ9HMeta?.name ?? "auth-recovery",
    path: recoveryycs7bKFZ9HMeta?.path ?? "/auth/recovery",
    meta: recoveryycs7bKFZ9HMeta || {},
    alias: recoveryycs7bKFZ9HMeta?.alias || [],
    redirect: recoveryycs7bKFZ9HMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/auth/recovery.vue").then(m => m.default || m)
  },
  {
    name: registerk5i5zWnRLhMeta?.name ?? "auth-register",
    path: registerk5i5zWnRLhMeta?.path ?? "/auth/register",
    meta: registerk5i5zWnRLhMeta || {},
    alias: registerk5i5zWnRLhMeta?.alias || [],
    redirect: registerk5i5zWnRLhMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: verify_45emaildxQ5NDQSZUMeta?.name ?? "auth-verify-email",
    path: verify_45emaildxQ5NDQSZUMeta?.path ?? "/auth/verify-email",
    meta: verify_45emaildxQ5NDQSZUMeta || {},
    alias: verify_45emaildxQ5NDQSZUMeta?.alias || [],
    redirect: verify_45emaildxQ5NDQSZUMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: plansWLBuQYUGPsMeta?.name ?? "billing-plans",
    path: plansWLBuQYUGPsMeta?.path ?? "/billing/plans",
    meta: plansWLBuQYUGPsMeta || {},
    alias: plansWLBuQYUGPsMeta?.alias || [],
    redirect: plansWLBuQYUGPsMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/billing/plans.vue").then(m => m.default || m)
  },
  {
    name: _91id_93duBgUJaaDoMeta?.name ?? "customer-care-plans-id",
    path: _91id_93duBgUJaaDoMeta?.path ?? "/customer-care/plans/:id()",
    meta: _91id_93duBgUJaaDoMeta || {},
    alias: _91id_93duBgUJaaDoMeta?.alias || [],
    redirect: _91id_93duBgUJaaDoMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/customer-care/plans/[id].vue").then(m => m.default || m)
  },
  {
    name: index3lgHD8McG7Meta?.name ?? "customer-care-plans",
    path: index3lgHD8McG7Meta?.path ?? "/customer-care/plans",
    meta: index3lgHD8McG7Meta || {},
    alias: index3lgHD8McG7Meta?.alias || [],
    redirect: index3lgHD8McG7Meta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/customer-care/plans/index.vue").then(m => m.default || m)
  },
  {
    name: usersLIQFoP25KhMeta?.name ?? "customer-care-users",
    path: usersLIQFoP25KhMeta?.path ?? "/customer-care/users",
    meta: usersLIQFoP25KhMeta || {},
    alias: usersLIQFoP25KhMeta?.alias || [],
    redirect: usersLIQFoP25KhMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/customer-care/users.vue").then(m => m.default || m)
  },
  {
    name: homeUB2KnuWhItMeta?.name ?? "home",
    path: homeUB2KnuWhItMeta?.path ?? "/home",
    meta: homeUB2KnuWhItMeta || {},
    alias: homeUB2KnuWhItMeta?.alias || [],
    redirect: homeUB2KnuWhItMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/home.vue").then(m => m.default || m)
  },
  {
    name: setupc34uEvDTQ1Meta?.name ?? "integrations-custom-setup",
    path: setupc34uEvDTQ1Meta?.path ?? "/integrations/custom/setup",
    meta: setupc34uEvDTQ1Meta || {},
    alias: setupc34uEvDTQ1Meta?.alias || [],
    redirect: setupc34uEvDTQ1Meta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/integrations/custom/setup.vue").then(m => m.default || m)
  },
  {
    name: setupkTn8jcnpTOMeta?.name ?? "integrations-magento-setup",
    path: setupkTn8jcnpTOMeta?.path ?? "/integrations/magento/setup",
    meta: setupkTn8jcnpTOMeta || {},
    alias: setupkTn8jcnpTOMeta?.alias || [],
    redirect: setupkTn8jcnpTOMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/integrations/magento/setup.vue").then(m => m.default || m)
  },
  {
    name: setupWYLqEa8JlYMeta?.name ?? "integrations-shopify-setup",
    path: setupWYLqEa8JlYMeta?.path ?? "/integrations/shopify/setup",
    meta: setupWYLqEa8JlYMeta || {},
    alias: setupWYLqEa8JlYMeta?.alias || [],
    redirect: setupWYLqEa8JlYMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/integrations/shopify/setup.vue").then(m => m.default || m)
  },
  {
    name: splashrmEMQY18PhMeta?.name ?? "integrations-shopify-splash",
    path: splashrmEMQY18PhMeta?.path ?? "/integrations/shopify/splash",
    meta: splashrmEMQY18PhMeta || {},
    alias: splashrmEMQY18PhMeta?.alias || [],
    redirect: splashrmEMQY18PhMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/integrations/shopify/splash.vue").then(m => m.default || m)
  },
  {
    name: movementsL4JBQDpjniMeta?.name ?? "movements",
    path: movementsL4JBQDpjniMeta?.path ?? "/movements",
    meta: movementsL4JBQDpjniMeta || {},
    alias: movementsL4JBQDpjniMeta?.alias || [],
    redirect: movementsL4JBQDpjniMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/movements.vue").then(m => m.default || m)
  },
  {
    name: notificationsyFXQpskHIBMeta?.name ?? "notifications",
    path: notificationsyFXQpskHIBMeta?.path ?? "/notifications",
    meta: notificationsyFXQpskHIBMeta || {},
    alias: notificationsyFXQpskHIBMeta?.alias || [],
    redirect: notificationsyFXQpskHIBMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: settingLJSGG2TT6FMeta?.name ?? "onboarding-setting",
    path: settingLJSGG2TT6FMeta?.path ?? "/onboarding/setting",
    meta: settingLJSGG2TT6FMeta || {},
    alias: settingLJSGG2TT6FMeta?.alias || [],
    redirect: settingLJSGG2TT6FMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/onboarding/setting.vue").then(m => m.default || m)
  },
  {
    name: organizationS9bR0ETI49Meta?.name ?? "organization",
    path: organizationS9bR0ETI49Meta?.path ?? "/organization",
    meta: organizationS9bR0ETI49Meta || {},
    alias: organizationS9bR0ETI49Meta?.alias || [],
    redirect: organizationS9bR0ETI49Meta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/organization.vue").then(m => m.default || m)
  },
  {
    name: automations_45rules74dbNZt7dLMeta?.name ?? "policy-automations-rules",
    path: automations_45rules74dbNZt7dLMeta?.path ?? "/policy/automations-rules",
    meta: automations_45rules74dbNZt7dLMeta || {},
    alias: automations_45rules74dbNZt7dLMeta?.alias || [],
    redirect: automations_45rules74dbNZt7dLMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/policy/automations-rules.vue").then(m => m.default || m)
  },
  {
    name: order_45lookupVk1sAVrAKJMeta?.name ?? "policy-order-lookup",
    path: order_45lookupVk1sAVrAKJMeta?.path ?? "/policy/order-lookup",
    meta: order_45lookupVk1sAVrAKJMeta || {},
    alias: order_45lookupVk1sAVrAKJMeta?.alias || [],
    redirect: order_45lookupVk1sAVrAKJMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/policy/order-lookup.vue").then(m => m.default || m)
  },
  {
    name: refund_45methodsTviFekpzFAMeta?.name ?? "policy-refund-methods",
    path: refund_45methodsTviFekpzFAMeta?.path ?? "/policy/refund-methods",
    meta: refund_45methodsTviFekpzFAMeta || {},
    alias: refund_45methodsTviFekpzFAMeta?.alias || [],
    redirect: refund_45methodsTviFekpzFAMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/policy/refund-methods.vue").then(m => m.default || m)
  },
  {
    name: return_45reasons27MFPudopUMeta?.name ?? "policy-return-reasons",
    path: return_45reasons27MFPudopUMeta?.path ?? "/policy/return-reasons",
    meta: return_45reasons27MFPudopUMeta || {},
    alias: return_45reasons27MFPudopUMeta?.alias || [],
    redirect: return_45reasons27MFPudopUMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/policy/return-reasons.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HgBTdpjzEvMeta?.name ?? "request-id",
    path: _91id_93HgBTdpjzEvMeta?.path ?? "/request/:id()",
    meta: _91id_93HgBTdpjzEvMeta || {},
    alias: _91id_93HgBTdpjzEvMeta?.alias || [],
    redirect: _91id_93HgBTdpjzEvMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/request/[id].vue").then(m => m.default || m)
  },
  {
    name: requeststP7SvabLN7Meta?.name ?? "requests",
    path: requeststP7SvabLN7Meta?.path ?? "/requests",
    meta: requeststP7SvabLN7Meta || {},
    alias: requeststP7SvabLN7Meta?.alias || [],
    redirect: requeststP7SvabLN7Meta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/requests.vue").then(m => m.default || m)
  },
  {
    name: returns_45pageE5tHE7AmSQMeta?.name ?? "returns-page",
    path: returns_45pageE5tHE7AmSQMeta?.path ?? "/returns-page",
    meta: returns_45pageE5tHE7AmSQMeta || {},
    alias: returns_45pageE5tHE7AmSQMeta?.alias || [],
    redirect: returns_45pageE5tHE7AmSQMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/returns-page.vue").then(m => m.default || m)
  },
  {
    name: settings78H5Q5OjM0Meta?.name ?? "settings",
    path: settings78H5Q5OjM0Meta?.path ?? "/settings",
    meta: settings78H5Q5OjM0Meta || {},
    alias: settings78H5Q5OjM0Meta?.alias || [],
    redirect: settings78H5Q5OjM0Meta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: shipping_45rulesrfjlkLMXUEMeta?.name ?? "shipping-rules",
    path: shipping_45rulesrfjlkLMXUEMeta?.path ?? "/shipping-rules",
    meta: shipping_45rulesrfjlkLMXUEMeta || {},
    alias: shipping_45rulesrfjlkLMXUEMeta?.alias || [],
    redirect: shipping_45rulesrfjlkLMXUEMeta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/shipping-rules.vue").then(m => m.default || m)
  },
  {
    name: store_45locationss9kKSzpal6Meta?.name ?? "store-locations",
    path: store_45locationss9kKSzpal6Meta?.path ?? "/store-locations",
    meta: store_45locationss9kKSzpal6Meta || {},
    alias: store_45locationss9kKSzpal6Meta?.alias || [],
    redirect: store_45locationss9kKSzpal6Meta?.redirect || undefined,
    component: () => import("/home/sendadmstaging/admin.staging.sendback.com/pages/store-locations.vue").then(m => m.default || m)
  }
]